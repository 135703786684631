import {GenericModel} from "@shared/models/generic.model";
import {IReturnTruck, IReturnTruckDto} from "@shared/interfaces/return-truck.interface";
import {ReturnTruckImageModel} from "@shared/models/return-truck-image.model";
import {ReturnTruckConfigurationModel} from "@shared/models/return-truck-configuration.model";
import {ESecondLifeDecision} from "@shared/enums/secondLifeDecision.enum";
import {ECategory} from "@shared/enums/category.enum";
import {EStatus} from "@shared/enums/status.enum";
import {ELocation} from "@shared/enums/location.enum";
import {EArea} from "@shared/enums/area.enum";

export class ReturnTruckModel extends GenericModel<IReturnTruck, IReturnTruckDto> implements IReturnTruck {
    id: number;
    manufacturer: string | null;
    cType: string | null;
    chassisNumber: string | null;
    constructionYear: string | null;
    operatingHours: string | null;
    secondLifeDecision: ESecondLifeDecision | null;
    category: ECategory | null;
    costEstimate: boolean;
    recordingPerson: string | null;
    inStock: boolean;
    status: EStatus | null | '';
    images: ReturnTruckImageModel[];
    location: ELocation | null;
    area: EArea | null;
    parking: string | null;
    urlOfImageOfLocation: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    configurations: ReturnTruckConfigurationModel[] | null;
    comment: string | null;

    constructor(data?: Partial<IReturnTruckDto>) {
        super(data, ReturnTruckModel);
        this.id = data?.id ?? 0;
        this.manufacturer = data?.manufacturer ?? null;
        this.cType = data?.cType ?? null;
        this.chassisNumber = data?.chassisNumber ?? null;
        this.constructionYear = data?.constructionYear ?? null;
        this.operatingHours = data?.operatingHours ?? null;
        this.secondLifeDecision = data?.secondLifeDecision ?? null;
        this.category = data?.category ?? null;
        this.costEstimate = data?.costEstimate ?? false;
        this.recordingPerson = data?.recordingPerson ?? null;
        this.inStock = data?.inStock ?? false;
        this.status = data?.status ?? null;
        this.images = []
        this.location = data?.location ?? null;
        this.area = data?.area ?? null;
        this.parking = data?.parking ?? null;
        this.urlOfImageOfLocation = data?.urlOfImageOfLocation ?? null;
        this.createdAt = data?.createdAt ?? null;
        this.updatedAt = data?.updatedAt ?? null;
        this.configurations = [];
        this.comment = data?.comment ?? null;

        data?.images?.forEach(image=>{
            let el: ReturnTruckImageModel = new ReturnTruckImageModel(image);
            this.images.push(el);
        })

        data?.configurations?.forEach(config=>{
            let el: ReturnTruckConfigurationModel = new ReturnTruckConfigurationModel(config);
            this.configurations?.push(el);
        })
    }
}